<script setup lang="ts">
import { onMounted, onUnmounted, getCurrentInstance, ref, computed } from 'vue';
import type { PropType } from 'vue';
import useUi2FormElement from '@mop/ui2/composables/useUi2FormElement';
import useUi2Config from '@mop/ui2/composables/useUi2Config';

defineOptions({
  name: 'Ui2FileUpload',
  inheritAttrs: false,
});

type FileUploadLocalisation = {
  click_to_upload: string;
  drag_content: string;
  drag_document_here: string;
  unsupported_file: string;
};

const emit = defineEmits(['update:modelValue']);
const { ui2T } = useUi2Config();
const props = defineProps({
  modelValue: {
    type: Object as PropType<FileList>,
    default: null,
  },
  name: {
    type: String,
    required: true,
  },
  id: {
    type: String,
    required: true,
  },
  rules: {
    type: Array,
    default: () => [],
  },
  accept: {
    type: String,
    default: '',
  },
  multiple: {
    type: Boolean,
    default: false,
  },
  disabled: {
    type: Boolean,
    default: false,
  },
  localisation: {
    type: Object as PropType<FileUploadLocalisation>,
    default: null,
  },
});

const { validationErrorsRef, register, unregister, validate } = useUi2FormElement(props);
defineExpose({ validate });
const hasErrorRef = computed(() => validationErrorsRef.value.length > 0);
const inputElement = ref<HTMLInputElement>();
let attachments: FileList;
const isDragOverRef = ref(false);
const acceptedFiles = props.accept.split(',');
const internalValidationErrorRef = ref('');
const fileSizeMb = 1024 * 1024;

function handleChange(event: Event) {
  event.preventDefault();
  event.stopPropagation();
  isDragOverRef.value = false;
  internalValidationErrorRef.value = '';

  const dataTransfer = new DataTransfer();
  if (attachments) {
    Object.keys(attachments).forEach((index: any) => {
      dataTransfer.items.add(attachments[index]);
    });
  }
  let incomingFiles: FileList | null;
  if (event.type === 'drop') {
    incomingFiles = (event as Event & { dataTransfer: DataTransfer }).dataTransfer.files;
  } else {
    incomingFiles =
      (event.target as HTMLInputElement).files || (event as Event & { dataTransfer: DataTransfer }).dataTransfer.files;
  }

  Object.keys(incomingFiles!).forEach((index: any) => {
    const newFile: File = incomingFiles![index];
    const isFileTypeAccepted =
      acceptedFiles.length === 0 || acceptedFiles.some((fileExtension) => newFile.name.endsWith(fileExtension));

    if (!props.multiple) {
      dataTransfer.items.clear();
    }
    if (!isFileTypeAccepted) {
      internalValidationErrorRef.value = props.localisation?.unsupported_file || ui2T('ui.fileUpload.unsupported_file');
    }
    const newFileExists = Object.keys(dataTransfer.files).some((key: any) => {
      const file = dataTransfer.files[key];
      return file.size === newFile.size && file.name === newFile.name;
    });
    if (!newFileExists && isFileTypeAccepted) {
      dataTransfer.items.add(newFile);
    }
  });
  attachments = dataTransfer.files;
  emit('update:modelValue', attachments);
  props.rules.length && validate();
}

function handleFileRemove(index: number) {
  internalValidationErrorRef.value = '';
  // have to re-add to keep the compatibility
  const list = new DataTransfer();
  Object.keys(props.modelValue).forEach((key: any, i: number) => {
    if (i !== index) {
      list.items.add(props.modelValue[key]);
    }
  });
  attachments = list.files;
  if (!attachments.length) {
    // othervise change is not triggered from the input
    inputElement.value!.value = '';
  }
  emit('update:modelValue', attachments);
  props.rules.length && validate();
}

function handleDragOver(event: Event) {
  event.stopPropagation();
  event.preventDefault();
  (event as Event & { dataTransfer: DataTransfer }).dataTransfer.dropEffect = 'copy';
  isDragOverRef.value = true;
}
function handleDragLeave() {
  isDragOverRef.value = false;
}

onMounted(() => {
  register(getCurrentInstance());
});

onUnmounted(() => {
  unregister(getCurrentInstance());
});
</script>

<template>
  <!-- eslint-disable-next-line vuejs-accessibility/no-static-element-interactions -->
  <div
    class="ui-file-upload-wrapper"
    :data-theme="$attrs['data-theme']"
    @drop.prevent="handleChange"
    @dragover.prevent="handleDragOver"
    @dragleave.prevent="handleDragLeave"
  >
    <div class="ui-file-upload">
      <input
        v-bind="$attrs"
        :id="id"
        ref="inputElement"
        type="file"
        :class="['ui-file-upload__input', { 'ui-file-upload__input-drag-over': isDragOverRef }]"
        :name="name"
        :disabled="disabled"
        :multiple="multiple"
        :accept="accept"
        @change="handleChange"
      />
      <label
        :for="id"
        class="ui-file-upload__button"
        :data-drag-content="localisation?.drag_content || $ui2Config.ui2T('ui.fileUpload.drag_content')"
      >
        <div class="ui-file-upload__wrap">
          <div class="ui-file-upload__highlight">
            <div class="ui-file-upload__icon">
              <Ui2Icon name="file" :width="12" :height="14" />
            </div>
            <div class="ui-file-upload__helper-text-highlight">
              {{ localisation?.click_to_upload || $ui2Config.ui2T('ui.fileUpload.click_to_upload') }}
            </div>
          </div>
          <div class="ui-file-upload__helper-text">
            {{ localisation?.drag_content || $ui2Config.ui2T('ui.fileUpload.drag_document_here') }}
          </div>
        </div>
        <div v-if="hasErrorRef || internalValidationErrorRef" class="ui-file-upload__error-text">
          {{ internalValidationErrorRef || validationErrorsRef[0] }}
        </div>
      </label>
    </div>

    <div v-if="modelValue && modelValue.length > 0" class="ui-file-upload__list">
      <div v-for="(file, index) in modelValue" :key="index" class="ui-file-upload__item">
        <Ui2Button
          class="ui-file-upload__icon-trash"
          type="ghost"
          size="sm"
          icon-start="trash"
          :width="16"
          :height="16"
          @click="handleFileRemove(index)"
        />
        <div class="ui-file-upload__item-name">
          <div class="ui-file-upload__icon">
            <Ui2Icon name="file" :width="12" :height="14" />
          </div>
          <span class="ui-file-upload__helper-text-highlight ui-file-upload__item-name-truncated">{{ file.name }}</span>
        </div>

        <div class="ui-file-upload__helper-text">
          {{ (file.size < fileSizeMb ? file.size / 1024 : file.size / fileSizeMb).toFixed(1)
          }}{{ file.size < fileSizeMb ? 'kB' : 'MB' }}
        </div>
      </div>
    </div>
  </div>
</template>

<style lang="scss">
.ui-file-upload {
  position: relative;
}

.ui-file-upload__button {
  cursor: pointer;
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  position: relative;
  padding: $space-16 $space-24;
  border-radius: $border-radius-rounded-md;
  border: 1px solid $color-border-primary;
  background: $color-surface-primary;
  color: $color-text-body-secondary;
}

.ui-file-upload__input-drag-over ~ .ui-file-upload__button {
  border: 2px dashed $color-border-focus;
  padding: calc($space-16 - 1px) calc($space-24 - 1px);

  &::after {
    position: absolute;
    background-color: $color-surface-primary;
    content: attr(data-drag-content);
    width: 100%;
    height: 100%;
    top: 0;
    display: flex;
    justify-content: center;
    align-items: center;
  }
}

.ui-file-upload__icon {
  height: 16px;
  width: 16px;
}

.ui-file-upload__wrap {
  display: flex;

  @include v2-apply-upto(mobile) {
    flex-direction: column;
    align-items: center;
  }
}

.ui-file-upload__highlight {
  display: flex;

  align-items: center;
}

.ui-file-upload__helper-text {
  @include v2-text-style(sm, regular);

  display: flex;
  align-items: center;
  color: $color-text-body-secondary;
}

.ui-file-upload__helper-text-highlight {
  @include v2-text-style(sm, regular);
  color: $color-text-body-primary;
  margin: 0 $space-4 0 $space-2;
}

.ui-file-upload__input {
  position: absolute;
  cursor: pointer;
  opacity: 0;
  width: 100%;
  height: 100%;

  &:disabled {
    pointer-events: none;
    cursor: not-allowed;
  }

  &:hover ~ .ui-file-upload__button {
    border-color: $color-border-action-hover;
    cursor: grab;
  }

  &:disabled ~ .ui-file-upload__button {
    pointer-events: none;
    opacity: $v2-disabled-opacity;
  }

  &:focus-visible ~ .ui-file-upload__button {
    border-color: $color-border-focus;
    box-shadow: 0px 0px 0px 2px $color-border-focus;
  }
}

.ui-file-upload__item {
  @include v2-text-style(sm);

  color: $color-text-body-primary;
  display: flex;
  padding: $space-16;
  flex-direction: column;
  border-radius: $border-radius-rounded-md;
  border: 1px solid $color-border-secondary;
  background: $color-surface-primary;
  margin-top: $space-12;
  position: relative;
}

.ui-file-upload__item-name {
  display: flex;
  color: $color-text-body-primary;
  align-items: center;
  padding-right: $space-16;
}

.ui-file-upload__item-name-truncated {
  color: $color-text-body-primary;
  text-overflow: ellipsis;
  display: block;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}

.ui-file-upload__icon-trash {
  position: absolute;
  right: $space-8;
  top: $space-8;
  width: 32px;
  padding: 0;
}

.ui-file-upload__error-text {
  @include v2-text-style(sm);

  color: $color-text-error;
  margin-top: $space-8;
}
</style>
